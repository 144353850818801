import React, { useState, useEffect, useContext } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'
import { AppProvider } from './contexts/AppContext'


import http from './utils/http'
import { getUserUrl } from './constants/urls'

import Router from './Router/Router'

import TranslationsContext from './contexts/TranslationsContext';
import FeedbackBar from './components/FeedbackBar/FeedbackBar';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: 'rgb(0, 153, 204)',
    },
    label: {
      main: 'rgba(0, 0, 0, 0.54)'
    }
  },
})

function App() {
  const userFromStorage = JSON.parse(localStorage.getItem('user'))
  const [user, setUser] = useState(userFromStorage)
  const [feedback, setFeedback] = useState(null)
  const translations = useContext(TranslationsContext).en

  // Set default questionnaire mode to 'edit'
  useEffect(() => {
    if (!localStorage.getItem('questionnaireMode')) {
      localStorage.setItem('questionnaireMode', 'edit')
    }
  });

  // fetch user
  useEffect(() => {
    async function fetchUser() {
      try {
        setFeedback(null)
        const { data: response } = await http.get(getUserUrl())

        setUser(response.data);
        localStorage.setItem('user', JSON.stringify(response.data))
      } catch (e) {
        setFeedback(e.message)
      }
    }

    !user && fetchUser()
  }, [user])

  const context = {
    user,
    translations,
  }

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppProvider value={context}>
          {feedback && <FeedbackBar feedback={feedback} />}
          <CssBaseline />
          <Router />
        </AppProvider>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
}

export default App
