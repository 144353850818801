
import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { Loader } from '../components/base'

import Header from '../components/Header/Header'

const HomeView = lazy(() =>
  import(/* webpackChunkName: 'Home' */ '../views/Home/HomeView')
)
const QuestionnairesView = lazy(() =>
  import(/* webpackChunkName: 'Questionnaires' */ '../views/Questionnaires/QuestionnairesView')
)
const QuestionnaireView = lazy(() =>
  import(/* webpackChunkName: 'Questionnaire' */ '../views/Questionnaire/QuestionnaireView')
)
const AnalysisLevelCalculationView = lazy(() =>
  import(/* webpackChunkName: 'AnalysisLevelCalculation' */ '../views/AnalysisLevelCalculation/AnalysisLevelCalculationView')
  )
const UserManagement = lazy(() =>
  import(/* webpackChunkName: 'UserManagement' */ '../views/UserManagement/UserManagementView')
)
const StcProductFamily = lazy(() =>
  import (/* webpackChunkName: 'StcToProductFamily' */ '../views/StcToProductFamily/StcToProductFamilyView')
)
const RoleToPermissions = lazy(() =>
  import (/* webpackChunkName: 'RoleToPermissions' */ '../views/RoleToPermissions/RoleToPermissionsView')
)
const EmailNotification = lazy(() =>
  import (/* webpackChunkName: 'EmailNotification' */ '../views/EmailNotification/EmailNotificationView')
)
const AuthenticationErrorView = lazy(() =>
  import(/* webpackChunkName: 'Authentication Error' */ '../views/AuthenticationError/AuthenticationErrorView')
)
const LogoutView = lazy(() =>
  import(/* webpackChunkName: 'Logout' */ '../views/Logout/LogoutView')
)

function Router() {
  return (
    <BrowserRouter>
      <Route
          path='*'
          component={props => {
            const { search, pathname } = props.location

            if (search.includes('?token=')) {
            // Handle token and redirect
              const urlParams = new URLSearchParams(window.location.search)
              const token = urlParams.get('token')
              localStorage.setItem('token', token)
              props.history.push(pathname)
          }

            return <Header {...props} />
        }}
      />
      <Switch>
          <Suspense fallback={<Loader global/>} >
            <Route exact path='/' component={props =>
              <HomeView {...props}/>}
          />
            <Route exact path='/questionnaires' component={props =>
              <QuestionnairesView {...props}/>}
            />
            <Route path='/questionnaires/:name/:snapshotId' component={props =>
              <QuestionnaireView {...props}/>}
            />
            <Route exact path='/analysis-level-calculation' component={props =>
              <AnalysisLevelCalculationView {...props}/>}
            />
            <Route exact path='/users' component={props =>
              <UserManagement {...props}/>}
            />
            <Route exact path='/stc-to-product-family' component={props =>
              <StcProductFamily {...props}/>}
            />
            <Route exact path='/role-to-permissions' component={props =>
              <RoleToPermissions {...props}/>}
            />
            <Route exact path="/email-notification" component={(props) => 
              <EmailNotification {...props} />} 
            />
            <Route exact path="/authentication-error" component={props =>
              <AuthenticationErrorView {...props}/>}
            />
            <Route exact path="/logout" component={props => <LogoutView {...props}/>} />
        </Suspense>
          <Route path='*' component={() => <Redirect to='/'/>} />
      </Switch>
    </BrowserRouter>
  )
}

export default Router
