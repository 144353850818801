const urlBase = `${process.env.REACT_APP_API_SERVER_URL}/api/admin`
export const rootUrl = () => '/'

export const authErrorPath = () => '/authentication-error'
export const logoutPath = () => '/logout'
export const questionnairesPath = () => '/questionnaires'

export const logoutUrl = () => `${urlBase}/logout`
export const authUrl = () => `${urlBase}/auth?redirectRoute=`
export const getUserUrl = () => `${urlBase}/auth/user`

export const getQuestionnairesUrl = () => `${urlBase}/product-families`
export const addQuestionnaireUrl = () => `${urlBase}/product-families/new`
export const deleteQuestionnaireUrl = id => `${urlBase}/questions/delete/${id}`

export const getQuestionnaireUrl = id => `${urlBase}/questions/${id}`
export const saveQuestionsUrl = () => `${urlBase}/questions/save`
export const publishQuestionnaireUrl = id => `${urlBase}/questions/publish/${id}`

export const getAnalysisLevelCalculationsUrl = () => `${urlBase}/analysis-level-calculation-tables`
export const analysisLevelCalculationUrl = type => `${urlBase}/analysis-level-${type}`
export const editAnalysisLevelCalculationUrl = (type, id) => `${urlBase}/analysis-level-${type}/${id}`

export const getStcProductFamilyUrl = () => `${urlBase}/stc-product-families`
export const addStcToProductFamiliesUrl = () => `${urlBase}/stc-product-families`
export const editStcToProductFamiliesUrl = id => `${urlBase}/stc-product-families/${id}`
export const deleteStcToProductFamiliesUrl = id =>  `${urlBase}/stc-product-families/${id}`

export const getPermissionsUrl = () => `${urlBase}/role-permissions/permissions`
export const getRoleAndPermissionsUrl = () => `${urlBase}/role-permissions`
export const editRoleAndPermissionsUrl = id => `${urlBase}/role-permissions/${id}`
export const addRoleAndPermissionsUrl = () => `${urlBase}/role-permissions`
export const deleteRoleAndPermissionsUrl = id => `${urlBase}/role-permissions/${id}`

export const getUsersUrl = () => `${urlBase}/users`
export const getUserByIdUrl = (id) => `${urlBase}/user/${id}`
export const getUserRolesUrl = () => `${urlBase}/user/roles`
export const getUserProductFamiliesUrl = () => `${urlBase}/user/get-product-families`
export const getUserServiceTechnologyCentersUrl = () => `${urlBase}/user/service-technology-centers`
export const getRolesUrl = () => `${urlBase}/role-permissions/roles`
export const getSTCPFProductFamiliesUrl = () => `${urlBase}/stc-product-families/get-product-families`
export const getSTCPFServiceTechnologyCentersUrl = () => `${urlBase}/stc-product-families/service-technology-centers`
export const getCustomerCentersUrl = () => `${urlBase}/customer-centers`
export const getDistributorsUrl = () => `${urlBase}/multiple-customer-centers-distributors`
export const getProductCentersUrl = () => `${urlBase}/product-centers`
export const getDivisionsUrl = () => `${urlBase}/divisions`
export const addUserUrl = () => `${urlBase}/user`
export const editUserUrl = id => `${urlBase}/user/${id}`
export const deleteUserUrl = id => `${urlBase}/user/${id}`

export const emailNotificationUrl = () => `${urlBase}/email-notification`
