import React from 'react';
import PropTypes from 'prop-types';

import { Fab as MuiFab } from '@material-ui/core';

const Fab = ({ children, onClick, ...rest }) => {
  return (
    <MuiFab
      {...rest}
      onClick={onClick}
    >
      { children }
    </MuiFab>
  )
}

Fab.defaultProps = {
  onClick: () => {},
};

Fab.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
};

export default Fab;
