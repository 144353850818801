import React from 'react'
import PropTypes from 'prop-types'

import { TableBody as MuiTableBody } from '@material-ui/core'

function TableBody({ children, ...props }) {
  return (
    <MuiTableBody
      {...props}
    >
      { children }
    </MuiTableBody>
  )
}

TableBody.defaultProps = {
  children: null,
};

TableBody.propTypes = {
  children: PropTypes.node,
};

export default TableBody
