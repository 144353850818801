import React from 'react';
import PropTypes from 'prop-types';

import { DialogContentText as MuiDialogContentText } from '@material-ui/core';

const DialogContentText = ({ children, ...rest }) => {
  return (
    <MuiDialogContentText
      {...rest}
    >
      { children }
    </MuiDialogContentText>
  )
}

DialogContentText.defaultProps = {
  children: null,
}

DialogContentText.propTypes = {
  children: PropTypes.node,
}

export default DialogContentText;
