import React from 'react'
import PropTypes from 'prop-types'

import Paper from '../Paper/Paper'

function TabPanel({ children, value, index, classes, ...rest }) {

  return (
    <Paper
      className={classes.root}
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...rest}
    >
      {children}
    </Paper>
  )
}

TabPanel.defaultProps = {
  children: null,
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
}

export default TabPanel;