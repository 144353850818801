import React from 'react';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import {
  logoutPath,
} from '../../constants/urls';

export const menuItems = [
  {
    displayName: 'Logout',
    path: logoutPath(),
    icon: <LogoutIcon />,
  },
];
