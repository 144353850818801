import React from 'react';
import PropTypes from 'prop-types';

import { Toolbar as MuiToolbar } from '@material-ui/core';

const Toolbar = ({ children, ...rest }) => {
  return (
    <MuiToolbar
      {...rest}
    >
      { children }
    </MuiToolbar>
  )
}

Toolbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Toolbar;
