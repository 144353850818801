import React from 'react';

import { Snackbar as MuiSnackbar } from '@material-ui/core';

const Snackbar = ({ children, ...rest }) => {
  return (
    <MuiSnackbar
      {...rest}
    >
      { children }
    </MuiSnackbar>
  )
}

export default Snackbar;
