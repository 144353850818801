import React from 'react';
import PropTypes from 'prop-types';

import { Typography as MuiTypography } from '@material-ui/core';

const Typography = ({ children, ...rest }) => {
  return (
    <MuiTypography
      {...rest}
    >
      { children }
    </MuiTypography>
  )
}

Typography.defaultProps = {
  children: null,
};

Typography.propTypes = {
  children: PropTypes.node,
};

export default Typography;
