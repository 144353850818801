import React from 'react';
import PropTypes from 'prop-types';

import { DialogContent as MuiDialogContent } from '@material-ui/core';

const DialogContent = ({ children, ...rest }) => {
  return (
    <MuiDialogContent
      {...rest}
    >
      { children }
    </MuiDialogContent>
  )
}

DialogContent.defaultProps = {
  children: null,
}

DialogContent.propTypes = {
  children: PropTypes.node,
}

export default DialogContent;
