import React from 'react'
import PropTypes from 'prop-types'

import { TableCell as MuiTableCell } from '@material-ui/core'

function TableCell({ children, ...props }) {
  return (
    <MuiTableCell
      {...props}
    >
      { children }
    </MuiTableCell>
  )
}

TableCell.defaultProps = {
  children: null,
};

TableCell.propTypes = {
  children: PropTypes.node,
};

export default TableCell
