export const en = {
  Global: {
    required: 'Required',
    genericError: 'Something went wrong',
    save: 'Save',
    publish: 'Publish',
    add: 'Add',
    cancel: 'Cancel',
    close: 'Close',
    subsection: 'Subsection',
    question: 'Question',
    attachment: 'Attachment',
    new: 'New',
  },
  Home: {
    questionnaires: 'Questionnaires',
    alcst: 'Analysis Level Calculation',
    users: 'User Management',
    stcToProductFamily: 'STC to Product Family Mapping',
    roleToPermissions: 'Role To Permissions Mapping',
    sendNotificationEmail: 'Send Notification Email',
  },
  Questionnaires: {
    title: 'Questionnaires',
    notPublished: 'not published',
    fetchErrorMessage: 'No questionnaires found.',
    forbiddenErrorMessage: 'Not enough privileges to see this page.',
    addQuestionnaireDialog: {
      title: 'Create questionnaire',
      confirmButton: 'Create',
      cancelButton: 'Cancel',
      nameFieldLabel: 'Product family',
      copyFromFieldLabel: 'Copy from questionnaire',
      error: 'Questionnaire with the same name already exists',
      required: 'Required',
    },
    deleteQuestionnaireDialog: {
      title: 'Please confirm questionnaire delete',
      content: 'Are you sure that you want to delete questionnaire',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    },
  },
  Questionnaire: {
    genericError: 'Something went wrong.',
    createdAt: 'Created at',
    modifiedAt: 'Modified at',
    publishedAt: 'Published at',
    receivedProductTabLabel: 'Received product',
    productEvaluationTabLabel: 'Product evaluation',
    stripObservationTabLabel: 'Strip observation',
    technicalInvestigationTabLabel: 'Technical investigation',
    conformityTestTabLabel: 'Conformity test',
    reportLevel: 'Report level',
    testAssessment: 'Test assessment',
    customerNumber: 'Customer number',
    customerCenterPlantCode: 'CC code',
    serviceTechnologyCenterPlannerGroup: 'STC code',
    missingQuestion: 'Missing question',
    questionTypes: {
      select: 'Select',
      multiselect: 'Multi select',
      input: 'Input',
      textarea: 'Textarea',
    },
    labelLabel: 'Label',
    questionTypeLabel: 'Question type',
    attachmentTypeLabel: 'Attachment type',
    attachmentTypes: {
      any: 'Any',
      image: 'Image',
      audio: 'Audio',
      video: 'Video',
    },
    excludeFromReports: 'Exclude from reports',
    conditionsTitle: 'Conditions',
    optionsTitle: 'Options',
    template: 'Template',
    importSubsectionButtonText: 'Import',
    addConditionDialog: {
      title: 'Add condition',
      selectQuestionLabel: 'Select question',
      selectValuesLabel: 'Select values',
      inputValueLabel: 'Value',
      confirmButtonText: 'Add',
      noQuestions: 'No potential dependant questions.',
      helpText: 'Only letters and numbers allowed',
    },
    addOptionDialog: {
      title: 'Add option',
      confirmButtonText: 'Add',
      addOptionLabel: 'Option label',
      duplicateError: 'This is a duplicate',
    },
    missingCauseCodeOptionsDialog: {
      title: 'Questionnaire cannot be published',
      content: 'Please add options for Cause Code question in Strip Observation section',
      buttonText: 'Close',
    },
    deleteQuestionModal: {
      title: 'Are you sure that you want to delete this question',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
      subtitle: 'Please confirm this action.',
      dependantKeys: 'Dependant keys',
      question: 'Question',
      subsectionWarning: 'Deleting this question will delete all of its children as well.',
    },
    importSubsectionDialog: {
      title: 'Import subsection',
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Import',
      importFromQuestionnaireLabel: 'Please select questionnaire',
      importFromSubsectionLabel: 'Please select subsection',
    },
    errors: {
      labelLength: 'Max 70 characters allowed.',
    },
  },
  AnalysisLevelCalculation: {
    title: 'Analysis Level Calculation',
    analysisLevelCalculationTable: {
      materialNumberTab: 'Material number',
      customerNumberTab: 'Customer number',
      serialNumberRangeTab: 'Serial number range',
      productHierarchyTab: 'Product hierarchy',
      compoundRulesetTab: 'Compound ruleset',
      showExpiredCheckbox: 'Show expired',
      searchPlaceholder: 'Search',
      noResults: 'No results.',
    },
    addCalculationForm: {
      titleAdd: 'Add new',
      titleEdit: 'Edit',
      materialNumber: 'Material number',
      customerNumber: 'Customer number',
      serialNumberRange: 'Serial number range',
      productHierarchy: 'Product hierarchy',
      compoundRuleset: 'Compound ruleset',
      questions: {
        customerName: 'Customer Name',
        productType: 'Product Type',
        reportLevel: 'Report Level',
        sourceSystem: 'Source System',
        releaseMethod: 'Release Method',
        validityDateFrom: 'Valid From',
        validityDateTo: 'Valid Thru',
        serialNumberLowerLimit: 'Serial Number Lower Limit',
        serialNumberUpperLimit: 'Serial Number Upper Limit',
        productHierarchy: 'Product hierarchy',
        compoundRuleOne: 'Select First Rule',
        compoundRuleTwo: 'Select Second Rule',
      },
      addParameterButtonText: 'Add Parameter',
      addRulesetParameterDialogTitle: 'Add ruleset parameter',
      addParameterDialogConfirmButton: 'Add Parameter',
      addParameterDialogCancelButton: 'Cancel',
      submitButtonText: 'Save',
      cancelButtonText: 'Cancel',
    },
  },
  AuthenticationError: {
    title: 'Authentication error!',
    paragraph1: 'You are not authorized to access this app.',
    paragraph2: 'Please contact your admin.',
    paragraph3: 'Or logout from Active Directory and try again.',
    button: 'Logout',
  },
  Feedback: {
    error: {
      generic: 'Something went wrong... Action failed.',
    },
    authentication: 'Authenticating... Please wait',
    special: {
      EmailAlreadyExist: 'Email already exists.'
    },
    success: {
      success: 'Action completed successfully',
      publish: 'Questionnaire published successfully',
    }
  },
  UserManagement: {
    title: 'User Management',
    userManagementTable: {
      noResults: 'No results.',
      moreDialog: {
        title: 'All',
        closeButtonText: 'Close'
      },
      searchPlaceholder: 'Search by email'
    },
    userForm: {
      titleAdd: 'Add new',
      titleEdit: 'Edit',
      materialNumber: 'Material number',
      customerNumber: 'Customer number',
      serialNumberRange: 'Serial number range',
      questions: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        receiveEmails: 'Receive Emails',
        role: 'Role',
        language: 'Language',
        company: 'Company',
        customerCenters: 'Customer Centers',
        distributors: 'Distributors',
        productCenters: 'Product Centers',
        productFamilies: 'Product Families',
        serviceTechnologyCenters: 'Service Technology Centers',
        division: 'Division',
        commercialDecisionLimit: 'Commercial Decision Limit'
      },
      submitButtonText: 'Save',
      cancelButtonText: 'Cancel',
      userFormList: {
        add: 'ADD'
      }
    }
  },
  STCToProductFamily: {
    title: 'STC to Product Family Mapping',
    STCToProductFamilyCRUDForm: {
      titleAdd: 'Add new',
      titleEdit: 'Edit',
      cancelButtonText: 'Cancel',
      submitButtonText: 'Save',
      yesButtonText: 'Yes',
      deleteButtonText: 'Delete',
      alertDialogTitle: 'Deleting STC',
      alertDialogText: 'This action will delete STC and all its related product families. Are you sure that you want to proceed?',
      stcProductFamiliesForm: {
        add: 'add',
      },
      selectFields: {
        stc: 'STC',
        productFamily: 'Product family'
      }
    },
  },
  RoleToPermissions: {
    title: 'Role to Permissions Mapping',
    role: 'Role',
    permissions: 'Permissions',
    RoleToPermissionsCRUDForm: {
      titleAdd: 'Add new',
      titleEdit: 'Edit',
      cancelButtonText: 'Cancel',
      submitButtonText: 'Save',
      yesButtonText: 'Yes',
      deleteButtonText: 'Delete',
      alertDialogTitle: 'Deleting role and permissions',
      alertDialogText: 'This action will delete Role and all its related permissions. Are you sure that you want to proceed?',
      roleToPermissionsForm: {
        add: 'add',
      },
      selectFields: {
        role: 'Role',
        permissions: 'Permissions',
      },
      permissions: {
        VIEW_JOB: 'View job',
        EDIT_JOB: 'Edit job',
        IMPORT_JOB_FROM_SAP: 'Import job from SAP',
        LEVEL_3_RELEASE_FOR_INVESTIGATION: 'Level 3 release for investigation',
        LEVEL_4_RELEASE_FOR_INVESTIGATION: 'Level 4 release for investigation',
        GENERATE_AND_DOWNLOAD_REPORT: 'Generate and download report',
        VIEW_LOG: 'View log',
        VIEW_CONVERSATION: 'View conversation',
        PARTICIPATE_IN_CONVERSATION: 'Participate in conversation',
        TAG_INTEREST: 'Tag interest',
        ADD_REMOVE_FROM_TEAM: 'Add and remove from team',
        CREATE_JOB: 'Create job',
        DELETE_JOB: 'Delete job',
        DELETE_IMPORTED_SAP_JOB: 'Delete imported sap job',
        REQUEST_DIFFERENT_REPORT_LEVEL: 'Request different report level',
        APPROVE_DIFFERENT_REPORT_LEVEL: 'Approve different report level',
        WARRANTY_DECISION: 'Warranty decision',
        GOODWILL_DECISION: 'Goodwill decision',
        LEVEL_1_REPORT_APPROVAL: 'Level 1 report approval',
        LEVEL_2_REPORT_APPROVAL: 'Level 2 report approval',
        LEVEL_3_REPORT_APPROVAL: 'Level 3 report approval',
        LEVEL_4_REPORT_APPROVAL: 'Level 4 report approval',
        LEVEL_1_REPORT_REOPEN: 'Level 1 report reopen',
        LEVEL_2_REPORT_REOPEN: 'Level 2 report reopen',
        LEVEL_3_REPORT_REOPEN: 'Level 3 report reopen',
        LEVEL_4_REPORT_REOPEN: 'Level 4 report reopen',
        REOPEN_REPORT: 'Reopen report',
        REQUEST_FAILED_PARTS: 'Request failed parts',
        SHIP_FAILED_PARTS: 'Ship failed parts',
        PRINT_INPUT_SHEETS: 'Print input sheets',
        ADMINISTRATE_QUESTIONS: 'Administrate questions',
        ADMINISTRATE_USERS: 'Administrate users',
        ADMINISTRATE_MAPPING_ROLES_TO_PERMISSIONS: 'Administrate mapping roles to permissions',
        ADMINISTRATE_REPORT_LEVEL_CALCULATION: 'Administrate report level calculation',
        ADMINISTRATE_MAPPING_STC_TO_PRODUCT_FAMILY: 'Administrate mapping STC to product family',
        DOWNLOAD_PICTURES: 'Download pictures',
        REQUEST_TECHNICAL_WARRANTY_DECISION: 'Request technical warranty decision',
        AMEND_APPROVED_TECHNICAL_DECISION: 'Amend approved technical warranty decision',
        AMEND_APPROVED_COMMERCIAL_DECISION: 'Amend approved commercial decision',
        ADD_INITIAL_WARRANTY_CODE: 'Add initial warranty code',
        RECLASSIFY_TECHNICAL_WARRANTY_CATEGORY: 'Reclassify technical warranty category',
        VIEW_ADMIN_QUESTIONS: 'View admin questions',
        EXTERNAL_REQUEST_REPORT_VIA_EMAIL_DELIVERY: 'External request report via email delivery',
        APPROVE_REPORT_LEVEL_4_DOWNGRADE: 'Approve report level 4 downgrade',
        SEND_NOTIFICATION_EMAIL: 'Send notification email',
        APPROVE_TECHNICAL_DECISION_LEVEL_4: 'Approve technical decision level 4'
      },
      roles: {
        RECEIVING: 'Receiving',
        SERVICE_ENGINEER: 'Service engineer',
        FAILURE_ANALYSIS_ENGINEER: 'Failure analysis engineer',
        SENIOR_FAILURE_ANALYSIS_ENGINEER: 'Senior failure analysis engineer',
        FAILURE_ANALYSIS_MANAGER: 'Failure analysis manager',
        SERVICE_TECHNOLOGY_CENTER_MANAGER: 'Service technology center manager',
        QUALITY_MANAGER: 'Quality manager',
        SERVICE_TECHNICAL_SUPPORT_TEAM: 'Service technical support team',
        PRODUCT_COMPANY_TECHNICAL_SUPPORT_TEAM: 'Product company technical support team',
        DIVISION_OR_BUSINESS_AREA: 'Division or business area',
        CUSTOMER_CENTER: 'Customer center',
        CUSTOMER: 'Customer',
        CUSTOMER_CENTER_SERVICE_ENGINEER: 'Customer center service engineer',
        SENIOR_CUSTOMER_CENTER_SERVICE_ENGINEER: 'Senior customer center service engineer',
        SUPERADMIN: 'Superadmin',
        ZONE_LEADER: 'Zone leader',
        QUESTION_ADMIN: 'Question admin',
        DISTRIBUTOR: 'Distributor',
        QUESTION_VIEWER: 'Question viewer',
        EXTERNAL_REQUESTER: 'External requester'
      },
    },
  },
  NotificationEmail: {
    title: 'Send Notification Email',
    send: 'Send email',
    subject: 'Subject',
    body: 'Text',
    roles: 'Roles'
  },
};
