import moment from 'moment'

export const timeFormat = {
  DATE: 'DD-MM-YYYY',
  TIME: 'DD-MM-YYYY HH:mm:ss',
  MINUTES: 'DD/MM/YYYY HH:mm',
  FILE_CREATED_AT: 'DD-MM-YYYY_HH:mm:ss',
}

const formats = [
  timeFormat.DATE,
  timeFormat.TIME,
  moment.HTML5_FMT.DATETIME_LOCAL_MS,
  moment.ISO_8601,
]

export const isValidDate = string => moment(string, formats, true).isValid()
