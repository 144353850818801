export const AUTO_HIDE_DURATION = 4000

export const infoMessages = [
  'Request failed with status code 401',
]

export const successMessages = [
  'success',
  'publish',
  'Form saved successfully',
]

export const specialErrors = [
  'EmailAlreadyExist'
]
