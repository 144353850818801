import React from 'react';
import PropTypes from 'prop-types';

import { Menu as MuiMenu } from '@material-ui/core';

const Menu = ({ children, open, ...rest }) => {
  return (
    <MuiMenu
      open={open}
      {...rest}
    >
      { children }
    </MuiMenu>
  )
}

Menu.defaultProps = {
  children: null,
}

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node,
}

export default Menu;
