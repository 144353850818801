import React from 'react';
import PropTypes from 'prop-types';

import { Dialog as MuiDialog } from '@material-ui/core';

const Dialog = ({ children, open, handleClose, ...rest }) => {
  return (
    <MuiDialog
      onClose={handleClose}
      open={open}
      {...rest}
    >
      { children }
    </MuiDialog>
  )
}

Dialog.defaultProps = {
  handleClose: () => {},
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func,
};

export default Dialog;
