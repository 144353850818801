import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
  AUTO_HIDE_DURATION,
  infoMessages,
  successMessages,
  specialErrors,
} from './constants'

import {
  Snackbar,
  SnackbarContent,
} from '../base'

import AppContext from '../../contexts/AppContext';

const useStyles = makeStyles(theme => ({
  snackbar: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  snackbarContentInfo: {
    background: 'white',
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  snackbarContentError: {
    background: theme.palette.error.light,
  },
  snackbarContentSuccess: {
    background: 'rgba(60, 118, 61, 0.8)',
  },
  close: {
    cursor: 'pointer',
  },
}));

function FeedbackBar({ feedback }) {
  const c = useStyles()
  const t = useContext(AppContext).translations.Feedback
  const isSpecial = specialErrors.includes(feedback)
  const [isOpen, setOpen] = useState(!!feedback)

  useEffect(() => {
    return () => setOpen(!!feedback)
  }, [feedback])

  function handleClose() {
    setOpen(false)
  }

  function renderMessage() {
    if (specialErrors.includes(feedback)) {
      return (
        <SnackbarContent
          className={c.snackbarContentError}
          message={<span id="generic-error-message">{t.special[feedback]}</span>}
        />
      )
    } else if (infoMessages.includes(feedback)) {
      return (
        <SnackbarContent
          className={c.snackbarContentInfo}
          message={<span id="generic-info-message">{t.authentication}</span>}
        />
      )
    } else if (successMessages.includes(feedback)) {
      return (
        <SnackbarContent
          className={c.snackbarContentSuccess}
          message={<span id="generic-success-message">{t.success[feedback]}</span>}
        />
      )
    } else {
      return (
        <SnackbarContent
          className={c.snackbarContentError}
          message={<span id="generic-error-message">{t.error['generic']}</span>}
        />
      )
    }
  }

  return (
    <Snackbar
      className={c.snackbar}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={isSpecial ? AUTO_HIDE_DURATION + 4000 : AUTO_HIDE_DURATION}
      ContentProps={{
        'aria-describedby': 'feedback-message',
      }}
      onClose={handleClose}
    >
      {renderMessage()}
    </Snackbar>
  )
}


export default FeedbackBar
