import React from 'react';
import PropTypes from 'prop-types';

import { Grid as MuiGrid } from '@material-ui/core';

const Grid = ({ children, ...rest }) => {
  return (
    <MuiGrid
      {...rest}
    >
      { children }
    </MuiGrid>
  )
}

Grid.defaultProps = {
  children: null
};

Grid.propTypes = {
  children: PropTypes.node,
};

export default Grid;
