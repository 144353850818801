import React from 'react';
import PropTypes from 'prop-types';

import { DialogActions as MuiDialogActions } from '@material-ui/core';

const DialogActions = ({ children, ...rest }) => {
  return (
    <MuiDialogActions
      {...rest}
    >
      { children }
    </MuiDialogActions>
  )
}

DialogActions.defaultProps = {
  children: null,
}

DialogActions.propTypes = {
  children: PropTypes.node,
}

export default DialogActions;
