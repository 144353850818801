import React, { useState } from 'react';

import TextField from '../TextField/TextField';

const NumberField = ({updateErrors, keyField, ...props}) => {
  const [error, setError] = useState()

  function onChange(event) {
    const value = event.target.value;

    if (isNaN(value)) {
      const errorText = 'Invalid number'
      setError(errorText)
      updateErrors({[keyField]: errorText})
    } else {
      updateErrors({[keyField]: null})
      props.onInput(event)
      setError(undefined)
    }
  }

  return (
    <TextField
      {...props}
      error= {!!error}
      helperText={error}
      onChange={onChange}
    />
  )
}

export default NumberField;
