import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem as MuiMenuItem } from '@material-ui/core';

const MenuItem = ({ children, ...rest }) => {
  return (
    <MuiMenuItem
      {...rest}
    >
      { children }
    </MuiMenuItem>
  )
}

MenuItem.defaultProps = {
  children: null,
}

MenuItem.propTypes = {
  children: PropTypes.node,
}

export default MenuItem;
