import React from 'react';
import PropTypes from 'prop-types';

import { Tabs as MuiTabs } from '@material-ui/core';

const Tabs = ({ children, ...rest }) => {
  return (
    <MuiTabs
      {...rest}
    >
      { children }
    </MuiTabs>
  )
}

Tabs.defaultProps = {
  children: null,
}

Tabs.propTypes = {
  children: PropTypes.node,
}

export default Tabs;