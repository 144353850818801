import React from 'react';
import PropTypes from 'prop-types';

import { DialogTitle as MuiDialogTitle } from '@material-ui/core';

const DialogTitle = ({ children, ...rest }) => {
  return (
    <MuiDialogTitle
      {...rest}
    >
      { children }
    </MuiDialogTitle>
  )
}

DialogTitle.propTypes = {
  children: PropTypes.node.isRequired,
}

export default DialogTitle;
