import React from 'react';
import PropTypes from 'prop-types';

import { Paper as MuiPaper } from '@material-ui/core';

const Paper = ({ children, ...rest }) => {
  return (
    <MuiPaper
      {...rest}
    >
      { children }
    </MuiPaper>
  )
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Paper;
