import React from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';

import {
  AlertDialog,
} from '../index';

export const FormDialogMobile = ({children, confirmDisabled, handleConfirm, ...props}) => {
  function handleFormSubmit(e) {
    e.preventDefault()

    if (!confirmDisabled) {
      handleConfirm()
    }
  }

  return (
    <AlertDialog
      handleConfirm={handleConfirm}
      confirmDisabled={confirmDisabled}
      type='submit'
      {...props}
    >
      <form onSubmit={handleFormSubmit}>
        {children}
      </form>
    </AlertDialog>
  );
}

FormDialogMobile.defaultProps = {
  confirmDisabled: false,
  handleConfirm: () => {},
};

FormDialogMobile.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  confirmDisabled: PropTypes.bool,
  handleConfirm: PropTypes.func,
};

export const FormDialog = withMobileDialog({breakpoint: 'xs'})(FormDialogMobile);
