import React, { useState } from 'react';

import TextField from '../TextField/TextField';

const EmailField = ({updateErrors, keyField, ...props}) => {
  const [error, setError] = useState()

  function onChange(event) {
    const value = event.target.value;

    // eslint-disable-next-line
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
      props.onInput(event)
      setError(undefined)
      updateErrors({[keyField]: null})
    } else {
      const errorText = 'Invalid email format'
      setError(errorText)
      updateErrors({[keyField]: errorText})
    }
  }

  return (
    <TextField
      {...props}
      error= {!!error}
      helperText={error}
      onChange={onChange}
    />
  )
}

export default EmailField;
