import React from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { createMuiTheme } from '@material-ui/core';
import { ThemeProvider } from "@material-ui/styles";

import { timeFormat } from '../../../constants/time';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: 'rgb(0, 153, 204)',
    },
  },
})

const datePickerOverrides = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: theme.palette.secondary.main,

        '& h6': {
          color: theme.palette.primary.main,
        },

        '& h4': {
          color: theme.palette.primary.main,
        }
      }
    },
    MuiPickersDay: {
      day: {
        color: theme.palette.text.primary,
      },
      daySelected: {
        backgroundColor: theme.palette.secondary.light,

        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
        },
      },
      current: {
        color: theme.palette.secondary.main,

        '&.isSelected': {
          color: theme.palette.primary.main,
        }
      },
    },
    MuiDialogActions: {
      spacing: {
        '& span': {
          color: theme.palette.secondary.main,
        },
      },
    },
  }
})

export const WrappedDatePicker = ({ onChange, value, t, ...rest }) =>
  <ThemeProvider theme={datePickerOverrides}>
    <KeyboardDatePicker
      format={timeFormat.DATE}
      onChange={onChange}
      value={value ? new Date(value) : null}
      cancelLabel={t.cancelLabel}
      okLabel={t.okLabel}
      maxDateMessage={t.maxDateMessage}
      minDateMessage={t.minDateMessage}
      invalidDateMessage={t.invalidDateMessage}
      {...rest}
    />
  </ThemeProvider>
