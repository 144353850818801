import React from 'react';
import PropTypes from 'prop-types';
import { Select as MuiSelect } from '@material-ui/core';

import MenuItem from '@material-ui/core/MenuItem';

const Select = ({ value, onChange, options, multiple, required, hasNullOption, ...rest }) => {
  const showNullOption = !required && hasNullOption;

  return (
    <MuiSelect
      {...rest}
      value={value}
      onChange={onChange}
      multiple={multiple}
    >
      {renderSelectOptions(options, showNullOption, multiple)}
    </MuiSelect>
  )
}

const renderSelectOptions = (options, showNullOption, isMultiple) => {
  options = showNullOption && !isMultiple ? [{ value: '', label: ''}].concat(options) : options;

  if (options && options.length) {
    return options.map(option => {
      if (Object.prototype.toString.call(option) === '[object Object]') {
        const { value, label } = option;

        return <MenuItem key={value} value={value} >{label}</MenuItem>
      } else if (typeof option === 'string') {
        return <MenuItem key={option} value={option} >{option}</MenuItem>
      }

      return [];
    })
  }
  return [];
}

Select.defaultProps = {
  value: '',
  multiple: false,
  hasNullOption: false,
  onChange: () => {},
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.number]),
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  hasNullOption: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOfType([
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
          description: PropTypes.string,
        }),
        PropTypes.shape({
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          label: PropTypes.string,
        }),
      ])
  ])).isRequired,
}

export default Select;
