import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import AppContext from '../../contexts/AppContext'

import {
  AppBar,
  Toolbar,
} from '../base'
import AppMenu from '../AppMenu/AppMenu'

import AtlasCopcoLogo from '../../assets/atlascopco.png'
import {
  rootUrl,
  logoutPath,
  authErrorPath,
} from '../../constants/urls'

import { fixedRoutes } from './constants'

const useStyles = makeStyles(theme => ({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  appBar: {
    position: 'static',
    maxWidth: '100vw',
  },
  fixed: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    boxShadow: 'none',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    height: 72,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  logo: {
    maxHeight: 50,
    maxWidth: 100,
  },
}))

function Header({ match: { url } }) {
  const c = useStyles()
  const { user } = useContext(AppContext)
  const [isFixed, setIsFixed] = useState(false)

  const storageListener = () => {
    const userId = user && user.id
    const newUser = JSON.parse(localStorage.getItem('user'))

    if (userId && newUser && userId !== newUser.id) {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (window) {
      window.addEventListener('storage', storageListener)
    }

    return () => {
      if (window) {
        return window.removeEventListener('storage', storageListener)
      }
    }
  })

  useEffect(() => {
    const fixed = !!fixedRoutes.find(route => url.startsWith(route))

    setIsFixed(fixed)
  }, [url])

  if ([logoutPath(), authErrorPath()].includes(url)) return null;

  return [logoutPath(), authErrorPath()].includes(url)
    ? null
    : <AppBar
        position={isFixed ? 'fixed' : 'static'}
        className={classNames(c.appBar, isFixed ? c.fixed : '')}
      >
        <Toolbar className={c.header}>
          <Link className={c.flexCenter} to={rootUrl()} >
            <img src={AtlasCopcoLogo} alt='AtlasCopco logo' className={c.logo} />
          </Link>
          <div className={c.flexCenter}>
            <AppMenu />
          </div>
        </Toolbar>
      </AppBar>
}

export default Header
