import React from 'react'
import PropTypes from 'prop-types'

import { TableRow as MuiTableRow } from '@material-ui/core'

function TableRow({ children, ...props }) {
  return (
    <MuiTableRow
      {...props}
    >
      { children }
    </MuiTableRow>
  )
}

TableRow.defaultProps = {
  children: null,
};

TableRow.propTypes = {
  children: PropTypes.node,
};

export default TableRow
