import React from 'react';
import PropTypes from 'prop-types';

import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  noPadding: {
    padding: 0,
    minWidth: 'fit-content',

    '&:hover': {
      background: 'inherit',
    },
  },
})

const Button = ({ children, noPadding, className, ...rest }) => {
  const c = useStyles();

  return (
    <MuiButton
      className={noPadding ? classNames(className, c.noPadding) : className}
      disableRipple
      {...rest}
    >
      { children }
    </MuiButton>
  )
}

Button.defaultProps = {
  onClick: () => {},
  noPadding: false,
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  noPadding: PropTypes.bool,
};

export default Button;
