import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import {
  Avatar,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from '../base';

import { menuItems } from './constants';

import AppContext from '../../contexts/AppContext';

const useStyles = makeStyles( theme => ({
  avatar: {
    cursor: 'pointer',
    fontSize: theme.typography.caption.fontSize,
  },
  menu: {
    '&>div+div': {
      top: `${theme.spacing(8)}px !important`,
    },

    '& ul': {
      padding: 0,
    },
  },
  menuUsername: {
    padding: theme.spacing(2),

    '&:focus': {
      outline: 'none',
    }
  },
  menuListItem: {
    padding: theme.spacing(),
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      minWidth: 150,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
    width: '100%',

    '&:active': {
      color: 'inherit'
    }
  },
  icon: {
    height: 24,
    marginRight: theme.spacing(),
  },
}));

function AppMenu({ history }) {
  const c = useStyles();
  const user = useContext(AppContext).user;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleNavigation = path => {
    if (path !== history.location.pathname) {
      history.push(path);
      handleClose();
    }
  }

  const open = Boolean(anchorEl),
        firstName = user ? user.firstName : '',
        lastName = user ? user.lastName : '',
        name = `${firstName} ${lastName}`.trim();

  return (
    <>
      <Avatar onClick={handleMenu} user={name} className={c.avatar} />
      <Menu
        className={c.menu}
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}
      >
        <li>
          <Typography
            className={c.menuUsername}
            variant='subtitle1'
          >
            { name }
          </Typography>
        </li>
        <Divider />
        { menuItems.map(item =>
            <MenuItem onClick={() => handleNavigation(item.path)} key={item.displayName} className={c.menuListItem}>
              <span className={c.link}>
                <i className={classNames("material-icons", c.icon)}>
                  {item.icon}
                </i>
                {item.displayName}
              </span>
            </MenuItem>
          )
        }
      </Menu>
    </>
  );
}

AppMenu.defaultProps = {
  handleMenu: () => {},
  handleClose: () => {},
  anchorEl: null,
  open: false,
  user: null,
};

AppMenu.propTypes = {
  handleMenu: PropTypes.func,
  handleClose: PropTypes.func,
  anchorEl: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.node,
  ]),
  open: PropTypes.bool,
  user: PropTypes.object,
}

export default withRouter(AppMenu);
