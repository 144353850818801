import React from 'react';
import PropTypes from 'prop-types';

import { Chip as MuiChip } from '@material-ui/core';

const Chip = ({ label, ...rest }) => {
  return (
    <MuiChip label={label} {...rest} />
  )
}

Chip.propTypes = {
  label: PropTypes.node.isRequired,
}

export default Chip;