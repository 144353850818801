import React from 'react';
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  global: {
    position: 'fixed',
    top: 200,
    left: 0,
    right: 0,
    bottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  }
})

const Loader = ({global, ...props}) => {
  const c = useStyles();

  return <CircularProgress className={global ? c.global : null} {...props} />
}

Loader.defaultProps = {
  global: false,
  color: 'secondary',
}

Loader.propTypes = {
  global: PropTypes.bool,
}


export default Loader;
