import axios from 'axios';

import { ERROR_CODE } from '../constants/errorCodes';

import { authUrl } from '../constants/urls';

/**
 * Wrapper for a separate axios instance, for API calls within the Admin app.
 *
 * @hideConstructor
 */
class Http {
  /**
   * Handles error response in interceptors.
   * @param {Object} error - error response
   * @return {Promise<never>} a Promise object rejected with the given reason
   */
  static handleError(error) {
    if (error.response) {
      if (
        error.response.status === Http.STATUS.UNAUTHORIZED &&
        error.response.data.code === ERROR_CODE.AUTHENTICATION_REQUIRED
      ) {
        // do nothing for now
      } else if (
        error.response.status === Http.STATUS.UNAUTHORIZED &&
        error.response.data.error.code === ERROR_CODE.AUTHENTICATION_REQUIRED
      ) {
        window.location.href = authUrl() + encodeURIComponent(window.location.pathname);
      } else if (
        error.response.status === Http.STATUS.FORBIDDEN &&
        error.response.data.error.code === ERROR_CODE.PERMISSION_DENIED
      ) {

      } else if (error.response.status === 404) {
        // if (error.response.data.error.message === ERROR_MESSAGE.NOT_FOUND) {
        //   window.location = rootUrl();
        // }

        // throw new Error(error.response.data.error.message)
      } else if (error.response.status === 409) {

      } else if (error.response.status >= 500 && error.response.status < 600) {
        // handles server errors
      } else {
        // do nothing for now
      }
    }

    return Promise.reject(error.response.data.error);
  }

  /**
   * Handles success response in interceptors.
   * @param {any} response - success response
   * @return {any} the given response
   */
  static handleSuccess(response) {
    // do something if needed
    return response;
  }

  /**
   * Creates a new axios instance, with interceptors.
   */
  constructor() {
    const http = axios.create();
    http.interceptors.request.use(config => {
      config.headers.post['authorization'] = localStorage.getItem('token');
      config.headers.get['authorization'] = localStorage.getItem('token');
      config.headers.delete['authorization'] = localStorage.getItem('token');
      config.headers.put['authorization'] = localStorage.getItem('token');
      config.headers.patch['authorization'] = localStorage.getItem('token');
      return config;
    })
    http.interceptors.response.use(Http.handleSuccess, Http.handleError);
    this.http = http;
  }

  /**
   * Handles DELETE requests.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [config={}] - axios config options
   * @return {Promise.<Object>}
   */
  delete(url, config = { headers: { 'Content-Type': 'application/json' }, data: null}) {
    return this.http.delete(url, config);
  }

  /**
   * Handles GET requests.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [config={}] - axios config options
   * @return {Promise.<Object>}
   */
  get(url, config = { headers: { 'Content-Type': 'application/json' }, data: null}) {
    return this.http.get(url, config);
  }

  getFile(url, config = { headers: { 'Content-Type': 'application/json' }, data: null, responseType: 'blob'}) {
    return this.http.get(url, config);
  }

  /**
   * Handles PATCH requests.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [data={}] - the data to be sent as the request body
   * @param {Object} [config={}] - axios config options
   * @return {Promise.<Object>}
   */
  patch(url, data = {}, config = {}) {
    return this.http.patch(url, data, config);
  }

  /**
   * Handles POST requests.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [data={}] - the data to be sent as the request body
   * @param {Object} [config={}] - axios config options
   * @return {Promise.<Object>}
   */
  post(url, data = {}, config = {}) {
    return this.http.post(url, data, config = { headers: { 'Content-Type': 'application/json' }});
  }

  /**
   * Handles POST requests with files.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [data={}] - the data to be sent as the request body
   * @return {Promise.<Object>}
   */
  postWithFiles(url, data = {}, config = {}) {
    const configWithHeaders = Object.assign({}, config, { headers: { 'Content-Type': 'multipart/form-data' }})

    return this.http.post(url, data, configWithHeaders);
  }

  /**
   * Handles PUT requests.
   *
   * @param {string} url - the server URL that will be used for the request
   * @param {Object} [data={}] - the data to be sent as the request body
   * @param {Object} [config={}] - axios config options
   * @return {Promise.<Object>}
   */
  put(url, data = {}, config = {}) {
    return this.http.put(url, data, config);
  }
}

Http.STATUS = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
};

export default new Http();
