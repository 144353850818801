import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import Pagination from 'material-ui-flat-pagination'

const useStyles = makeStyles(theme => ({
  center: {
    width: 'fit-content',
    margin: '0 auto',
  },
  text: {
    color: theme.palette.text.secondary,
  },
}))

function ParsPagination({ limit, offset, total, onClick, center, ...props }) {
  const c = useStyles()

  return (
    <Pagination
      classes={center ? {root: c.center, textPrimary: c.text} : {textPrimary: c.text}}
      limit={limit}
      offset={offset}
      total={total}
      onClick={onClick}
      {...props}
    />
  )
}

ParsPagination.defaultProps = {
  center: true,
}

ParsPagination.propTypes = {
  limit: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  center: PropTypes.bool,
}

export default ParsPagination
