import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MuiAvatar } from '@material-ui/core';

import PersonIcon from '@material-ui/icons/Person';

const getInitials = name =>
  name
    .split(' ')
    .reduce((acc, word) => /^[A-Z]/.test(word) ? acc += word[0] : acc, '');

function Avatar({ onClick, altText, src, className, user, ...rest }) {
  return (
    <MuiAvatar
      className={className}
      alt={altText}
      onClick={onClick}
      src={src}
      {...rest}
    >
      {user && !src ? getInitials(user) : <PersonIcon />}
    </MuiAvatar>
  );
}

Avatar.defaultProps = {
  altText: 'current user avatar',
  onClick: () => {},
}

Avatar.propTypes = {
  altText: PropTypes.string,
  onClick: PropTypes.func,
  src: PropTypes.string,
  user: PropTypes.string,
}

export default Avatar;
