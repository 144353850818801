import React from 'react';
import PropTypes from 'prop-types';

import { Drawer as MuiDrawer } from '@material-ui/core';

const Drawer = ({ children, ...rest }) => {
  return (
    <MuiDrawer
      {...rest}
    >
      { children }
    </MuiDrawer>
  )
}

Drawer.defaultProps = {
  children: null,
}

Drawer.propTypes = {
  children: PropTypes.node,
}

export default Drawer;
