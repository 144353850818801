import React from 'react'
import PropTypes from 'prop-types'

import { TableHead as MuiTableHead } from '@material-ui/core'

function TableHead({ children, ...props }) {
  return (
    <MuiTableHead
      {...props}
    >
      { children }
    </MuiTableHead>
  )
}

TableHead.defaultProps = {
  children: null,
};

TableHead.propTypes = {
  children: PropTypes.node,
};

export default TableHead
