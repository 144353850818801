import React from 'react';
import PropTypes from 'prop-types';

import { InputLabel as MuiInputLabel } from '@material-ui/core';

const InputLabel = ({ children, ...rest }) => {
  return (
    <MuiInputLabel
      {...rest}
    >
      { children }
    </MuiInputLabel>
  )
}

InputLabel.defaultProps = {
  children: null,
}

InputLabel.propTypes = {
  children: PropTypes.node,
}

export default InputLabel;
