import React from 'react';
import PropTypes from 'prop-types';

import { AppBar as MuiAppBar } from '@material-ui/core';

const AppBar = React.forwardRef(({ children, ...rest }, ref) =>
  <MuiAppBar
    {...rest}
    ref={ref}
  >
    { children }
  </MuiAppBar>
)

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AppBar;
