import React from 'react';
import PropTypes from 'prop-types';

import { FormControl as MuiFormControl } from '@material-ui/core';

const FormControl = ({ children, ...rest }) => {
  return (
    <MuiFormControl
      {...rest}
    >
      { children }
    </MuiFormControl>
  )
}

FormControl.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormControl;
