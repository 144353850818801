import React from 'react'
import PropTypes from 'prop-types'

import { Table as MuiTable } from '@material-ui/core'

function Table({ children, ...props }) {
  return (
    <MuiTable
      {...props}
    >
      { children }
    </MuiTable>
  )
}

Table.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Table
